"use strict";

import * as errorHandler from "paycentral.sdk.error";
import stripeSdkActionHandler from "paycentral.sdk.paymentmethod.stripe";
import * as stripe from "paycentral.sdk.paymentmethod.stripe";

/*
 * Get the external scripts that must be loaded for a given provider or payment method
 */
export function getExternalScripts(paymentMethod: PayCentral.PaymentMethod) {
    if (/stripe/i.test(paymentMethod.gateway.provider))
        return stripe.externalScripts;

    return [];
}

export function getActionHandler(action): PayCentral.Internal.ActionHandler {

    if (!action || !action.type) {
        throw new Error('handleAction::Invalid Action - the passed action object does not have a "type" property');
    }

    if (action.type === "psp") {
        if (!action.pspData) {
            throw new Error('handleAction::Invalid Action - the passed "psp" action object does not have a "pspData" property');
        }
        if (!action.pspData.provider) {
            throw new Error('handleAction::Invalid Action - the passed "psp" action object does not have a "provider" property');
        } 
    }

    switch (action.type.toLowerCase()) {
        case "psp":

            // Handle payment service provider "sdk" action
            if (/stripe/i.test(action.pspData.provider))
                return stripeSdkActionHandler();

        default:
            throw errorHandler.toPayCentralError("Could not establish an appropriate action handler.");
    }
    
}
