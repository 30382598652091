"use strict";

import * as logger from "paycentral.sdk.logger";
import * as utilities from "paycentral.sdk.utilities";
import * as errorHandler from "paycentral.sdk.error";
import * as threedsFactory from "paycentral.sdk.3ds.factory";
import * as actions from "paycentral.sdk.action.factory";
import * as messaging from "paycentral.sdk.messaging";
import * as helper from "paycentral.sdk.paymentcontrol.helper";
import * as scriptmanager from "paycentral.sdk.scriptmanager";
import { flags } from "paycentral.sdk.flags";
import * as abortable from "paycentral.sdk.abortable";
import * as environment from "paycentral.sdk.environment";

const frameLoadTimeout = 15000;
const submitTimeout = 60000;

let frameId = 0;

declare global {
    interface Window {
        appInsights: any;
    }
}

interface PingHandler {
    id: number;
    callback: () => void;
    active: boolean;
    destroy: () => void;
}

interface MessageHandler {
    id: number;
    type: string;
    callback: (data?) => void;
    active: boolean;
    destroy: () => void;
}

interface FrameUnresponsiveSubscription {
    id: number;
    callback: () => void;
}

interface FrameUnresponsivePromise {
    promise: Promise<void>;
    destroy: () => void;
}

export function create(paycentralInstance: PayCentral.Internal.PayCentralInstance,
    paymentMethod: PayCentral.PaymentMethod,
    paymentRequestOptions: PayCentral.PaymentRequestOptions): PayCentral.Internal.PaymentControl {
    return new PaymentControl(paycentralInstance, paymentMethod, paymentRequestOptions).api();
}

class PaymentControl {

    paycentralInstance: PayCentral.Internal.PayCentralInstance;
    paymentMethod: PayCentral.PaymentMethod;
    paymentRequestOptions: PayCentral.PaymentRequestOptions;

    loadedState: "new" | "loading" | "loaded" | "failed" = "new";
    destroyed = false;
    complete = false;
    iframeSrc: string;
    iframe: HTMLIFrameElement;
    messageListener: PayCentral.Internal.MessageListener;
    message: PayCentral.Internal.Message;
    pingGroup = Math.floor(Math.random() * 10000000);
    pingCounter = 0;
    pingHandlers: PingHandler[] = [];
    messageHandlers: MessageHandler[] = [];
    frameMonitorCount = 0;
    frameUnresponsive = false;
    frameUnresponsiveSubscriptions: FrameUnresponsiveSubscription[] = [];
    frameUnresponsiveSubscriptionCount = 0;
    timers = [];
    abortableOperation;

    constructor(paycentralInstance: PayCentral.Internal.PayCentralInstance,
        paymentMethod: PayCentral.PaymentMethod,
        paymentRequestOptions: PayCentral.PaymentRequestOptions
    ) {
        this.paycentralInstance = paycentralInstance;
        this.paymentMethod = paymentMethod;
        this.paymentRequestOptions = paymentRequestOptions;
    }

    /*
     * Return an api to this instance
     */
    api(): PayCentral.Internal.PaymentControl {
        const me = this;
        return {
            load: (container, options?) => me.load(container, options),
            destroy: () => me.destroy(),
            submit: () => me.submit(),
            update: (options) => me.update(options),
            get destroyed() { return me.destroyed },
            get complete() { return me.complete },
        };
    }

    // START API -- functions exposed via the API

    async submit(): Promise<PayCentral.PaymentResult> {

        logger.verboseinfo("paycentral.sdk.paymentcontrol.submit() called.", this.getLogProperties());

        this.throwIfNotLoaded();
        this.throwIfDestroyed();
        this.throwIfComplete();

        // Slightly controversial, but probably correct.
        // If we already think that the frame is unresponsive then we should bail out now.
        // This will guard against this scenario ....
        // submit() is called, and results in a timeout page being loaded into the frame which renders the frame unresponsive.
        // submit gets called again e.g. by a user ignoring any messages we display and just having another go.
        // At this point posting messages to the frame will achieve nothing (because the widget isn't loaded into it) 
        // and the content of the frame isn't going to change anymore.
        // This means the frameMonitor() which pings the frame when the frame content changes isn't going to get triggered 
        // meaning the FrameUnresponsivePromise.promise is never going to reject.
        // The end result is that we have to wait for the submission timeout to reject the submit promise.
        // If we already know the frame is unresponsive then we might as well reject it straight away.
        this.throwIfUnresponsive();

        const data = helper.composeMessageData(this.paymentMethod, this.paymentRequestOptions, this.iframeSrc, this.paycentralInstance.origin);

        if (flags.logPrimaryPayload) console.debug(`Primary Payload = ${JSON.stringify(data)}`);

        this.postOutboundMessage(data);

        const frameUnresponsivePromise = this.createFrameUnresponsivePromise();
        try {

            logger.verboseinfo("paycentral.sdk.paymentcontrol.submit() begin await race.", this.getLogProperties());
            var result = await Promise.race([this.handleSubmitResponses(), frameUnresponsivePromise.promise]);
            logger.verboseinfo("paycentral.sdk.paymentcontrol.submit() end await race.", { ...this.getLogProperties(), result: result });

            // @ts-ignore
            // complaining that the frameUnresponsivePromise.promise does not return a PayCentral.PaymentResult, but we know we only ever reject that promise, it never resolves
            return result;

        } catch (e) {
            logger.verboseinfo("paycentral.sdk.paymentcontrol.submit() failed with error.", { ...this.getLogProperties(), error: e });
            throw errorHandler.toPayCentralError(e);
        } finally {
            frameUnresponsivePromise.destroy();
        }

    }

    async update(options: PayCentral.PaymentRequestOptions): Promise<void> {

        logger.verboseinfo("paycentral.sdk.paymentcontrol.udpate() called.", this.getLogProperties());

        this.throwIfNotLoaded();
        this.throwIfDestroyed();
        this.throwIfComplete();

        // Slightly controversial, but probably correct.
        // If we already think that the frame is unresponsive then we should bail out now.
        // This will guard against this scenario ....
        // submit() is called, and results in a timeout page being loaded into the frame which renders the frame unresponsive.
        // submit gets called again e.g. by a user ignoring any messages we display and just having another go.
        // At this point posting messages to the frame will achieve nothing (because the widget isn't loaded into it) 
        // and the content of the frame isn't going to change anymore.
        // This means the frameMonitor() which pings the frame when the frame content changes isn't going to get triggered 
        // meaning the FrameUnresponsivePromise.promise is never going to reject.
        // The end result is that we have to wait for the submission timeout to reject the submit promise.
        // If we already know the frame is unresponsive then we might as well reject it straight away.
        this.throwIfUnresponsive();

        this.paymentRequestOptions = options;

        // Future proofing for a scenario where we may need to reload the payment UI.
        // At present none of the values supplied in PaymentRequestOptions require the UI to be reloaded.
        // If such a situation arises we could reload a UI.
        // This function is declared async in case it needs to await anything required to redraw the UI.

    }

    destroy(doNotRemoveFrame?: boolean, doNotCallAborter?: boolean) {

        logger.verboseinfo("paycentral.sdk.paymentcontrol.destroy() called.", this.getLogProperties());

        if (this.destroyed) return;

        // Abort any pending AbortableOperation.
        // This will immediately force the associated promise to reject and teardown anything it is doing.
        // e.g. remove a payment frame from the page that has not yet been fully loaded
        // The aborted promise itself will not appear to reject to its awaiter until the next tick 
        // i.e. after the current stack (the one that issued the abort) either completes OR awaits something itself (freeing up the thread to process something else).
        // Remember, there is only a single thread in javascript processing the event loop, and settlement of promises 
        // is queued like all other events.
        // We do not await the abort, it is synchronous.
        if (this.abortableOperation && doNotCallAborter !== true) this.abortableOperation.abort("The PaymentControl was destroyed.");

        this.destroyUi(doNotRemoveFrame);

        // destroy any ping handlers
        while (this.pingHandlers.length > 0) {
            this.destroyPingHandler(this.pingHandlers[0].id);
        }

        // destroy any frame unresponsive subscribers
        this.frameUnresponsiveSubscriptions.length = 0;

        // destroy any message listeners
        messaging.unsubscribe(this);
        if (this.messageListener) {
            this.messageListener.terminate("The payment control was destroyed.");
            this.messageListener = null;
        }

        // destroy any timers
        for (const timer of this.timers) {
            // no harm in clearing an already expired/cleared timer
            clearTimeout(timer);
        }

        this.destroyed = true;

    }

    /*
     * After the iframe has loaded, any postMessage() messages received by the SDK will be routed here for processing.
     * If you handle the message, return true to stop it being routed to any other handlers.
     * If you don't handle the message, return false.
     */
    tryHandleMessage(e): boolean {

        if (this.destroyed) return false;

        logger.verboseinfo("paycentral.sdk.paymentcontrol.tryHandleMessage() called.", { ...this.getLogProperties, data: e?.data });

        const frameOrigin = this.getFrameOrigin();
        if (e.origin !== frameOrigin) {
            // This should never happen
            logger.info(`Received a message from origin ${e.origin}. The message origin does not match the frame origin ${frameOrigin}. This message will be ignored.`);
            return false;
        }

        if (typeof e.data === "string" && e.data.startsWith("paycentral iFrame resize: ")) {
            // There was a request to resize the iFrame
            if (this.iframe) {
                const height = parseFloat(e.data.match(/[\d\.]+/)[0]);
                this.iframe.style.height = height + "px";
            }
            return true;
        }

        // handle responses to ping
        if (typeof e.data === "string" && e.data.startsWith("pong-")) {
            return this.handlePingResponse(e.data);
        }

        // handle message read receipts
        if (typeof e.data.type === "string" && e.data.type === "ack") {
            return this.handleMessageResponse(e.data);
        }

        // handle action messages
        if (typeof e?.data?.action === "object" && (typeof e?.data?.type === "string" && e?.data?.type === "action")) {
            this.handleAction(e.data);
            return true;
        }

        // handle error messages
        if (e.data.error) {
            this.handleError(e.data);
            return true;
        }

        if (this.complete) {
            logger.info(`Received a message from origin ${e.origin} but the PaymentControl it was destined for has already completed.`);
            return false;
        }

        if (this.messageListener) {
            this.messageListener.receive(e);
            return true;
        }

        logger.verboseinfo("paycentral.sdk.paymentcontrol.tryHandleMessage() did not handle the message.");

        return false;

    }

    // END API

    throwIfNotLoaded() {
        if (this.loadedState !== "loaded")
            throw errorHandler.toPayCentralError("This payment control has not been loaded.");
    }

    throwIfDestroyed() {
        if (this.destroyed)
            throw errorHandler.toPayCentralError("This payment control has been destroyed and can no longer be acted on.");
    }

    throwIfComplete() {
        if (this.complete) throw errorHandler.toPayCentralError("This payment control has completed and can no longer be acted on.");
    }

    throwIfUnresponsive() {
        if (this.frameUnresponsive) throw errorHandler.error_ComponentUnresponsive();
    }

    handleError(error: any): Promise<void> {
        this.destroy(false);
        throw errorHandler.toPayCentralError(error.data);
    }

    async handleAction(message: any): Promise<any> {
        return await actions.getProvider(message.action)?.handleAction(message.action);
    }

    async handleSubmitResponses(): Promise<PayCentral.PaymentResult> {

        let threeDSCode: string = null;

        const maxWaitTime = this.getTimeout(flags.submitTimeout, submitTimeout);

        // Could have multiple messages coming in from paycentral, especially if a 3ds challenge is issued.
        // Continue listening in a loop until we get something we recognise.
        // Update 2024-06-17  Telemetry shows that we are recieving messages originating from inside the iframe
        // which are not of our making. These appear to be coming from browser extensions and possibly from transpiled code.
        // Instead of erroring out when we recieve an unexpected message, we just ignore it and keep listening for something we recognise.
        let iterationWaitTime: number;
        let startWait: number;
        let waitedFor: number;
        let ignoredLastMessage = false;
        do {

            if (ignoredLastMessage) {
                // If we received a message but ignored it, then carry on waiting for another, but do not reset the wait time.
                // If we did reset the timeout, and kept receiving messages we ignore, then we may never timeout.
                iterationWaitTime -= waitedFor;
                ignoredLastMessage = false;
            }
            else
                iterationWaitTime = maxWaitTime;

            startWait = Date.now();
            const rawMessage = await this.waitForInboundMessage(Math.max(iterationWaitTime, 0));
            waitedFor = Date.now() - startWait;

            if (rawMessage.timeout) {
                logger.verboseinfo(`paycentral.sdk.paymentcontrol.handleSubmitResponses() waited ${maxWaitTime}ms for a recognisable message but did not recieve any.`, this.getLogProperties());
                throw "submit() timed out without a message from PayCentral.";
            }

            const message = this.parsePaymentMessage(rawMessage.data, threeDSCode);

            switch (message.actionCode) {
                case "DV-RESPONSE":
                    logger.verboseinfo('paycentral.sdk.paymentcontrol.handleSubmitResponses() is handling a DV-RESPONSE message.')
                    let result = this.handleDvResponseMessage(message);
                    return result;
                case "EXTERNALUI-ERROR":
                    logger.verboseinfo('paycentral.sdk.paymentcontrol.handleSubmitResponses() is handling a EXTERNALUI-ERROR message.')
                    let handleExternalError = this.handleDvResponseMessage_externalerror()
                    return handleExternalError;
                case "DV-CHALLENGE":
                    logger.verboseinfo('paycentral.sdk.paymentcontrol.handleSubmitResponses() is handling a DV-CHALLENGE message.')
                    const challenge = await this.handleDvChallenge(message);
                    if (challenge.succeeded) {
                        threeDSCode = challenge.threeDSCode;
                        this.postOutboundMessage(challenge); //"Challenge successful"
                        // now we listen for a DV-RESPONSE
                    } else {
                        return {
                            succeeded: false,
                            capture: this.paymentMethod.capture,
                            errors: challenge.errors
                        };
                    }
                    break;
                case "ACTION":
                    logger.verboseinfo('paycentral.sdk.paymentcontrol.handleSubmitResponses() is handling a ACTION message.')
                    const paymentMethodDetails = await this.handleAction(message.data);
                    if (paymentMethodDetails.error) {
                        console.log("error", paymentMethodDetails.error);
                        return this.handleDvResponseMessage_error({ actionCode: "error", data: `${paymentMethodDetails.error}` });
                    } else if (paymentMethodDetails.validationError) {
                        console.log("validationError", paymentMethodDetails.validationError);
                        return this.handleDvResponseMessage_externalerror();
                    } else if (paymentMethodDetails.success) {
                        console.log("success", paymentMethodDetails);
                        const data = helper.composeActionMessageData("ui.submit", this.paymentMethod, this.paymentRequestOptions, { paymentMethodDetails });
                        this.postOutboundMessage(data);
                    }
                    break;
                case "PAYCENTRAL IFRAME RESIZE":
                    logger.verboseinfo('paycentral.sdk.paymentcontrol.handleSubmitResponses() is handling a PAYCENTRAL IFRAME RESIZE message.')
                    if (this.iframe && message.data) {
                        // data should look like this "data":" 164.0109375 px"
                        this.iframe.style.height = message.data;
                    }
                    // keep listening?
                    break;
                case "NOACTION":
                    logger.verboseinfo('paycentral.sdk.paymentcontrol.handleSubmitResponses() is handling a NOACTION message.')
                    // keep listening
                    break;
                default:
                    ignoredLastMessage = true;
                    logger.info(`paycentral.sdk.paymentcontrol.handleSubmitResponses() received an unrecognisable message. Ignoring the message and continuing to listen.`,
                        { message: rawMessage.data?.data });

            }
        } while (true)

    }

    parsePaymentMessage(rawMessage: any, threeDSCode: string): PayCentral.Internal.InboundPaymentMessage {
        // Submit action parsing.
        if (typeof rawMessage?.data?.action === "object" && (typeof rawMessage?.data?.type === "string" && rawMessage?.data?.type === "response")) {
            return { actionCode: "ACTION", data: rawMessage.data };
        }

        // a message is formed as follows
        // [action]:[data/errors]
        // [action]:[data/errors]#3ds#[3ds code]

        if (typeof (rawMessage.data) !== "string" || !rawMessage.data) {
            return { actionCode: "NOACTION" };
        }

        const message: any = {};

        let i = rawMessage.data.indexOf(":");
        let data = "";
        if (i > -1) {
            message.actionCode = rawMessage.data.substring(0, i).toUpperCase();
            data = rawMessage.data.substring(i + 1);
        } else {
            message.actionCode = rawMessage.data.toUpperCase();
        }

        i = data.indexOf("#3ds#");
        if (i > -1) {
            message.data = data.substring(0, i);
            message.threeDSCode = data.substring(i + 5);
        } else {
            message.data = data;
            message.threeDSCode = threeDSCode;
        }

        try {
            message.json = JSON.parse(message.data);
        } catch (error) {
            message.error = true;
        }

        return message;
    }

    handleDvResponseMessage(message: PayCentral.Internal.InboundPaymentMessage): PayCentral.PaymentResult {

        if (message.error || !message.data) return this.handleDvResponseMessage_error(message);

        switch (this.paymentMethod.capture) {
            case "Delayed":
                return this.handleDvResponseMessage_delayed(message);
            case "Immediate":
                return this.handleDvResponseMessage_immediate(message);
            default:
                throw errorHandler.toPayCentralError(
                    `Cannot handle submit() response for capture mode of ${this.paymentMethod.capture}.`);
        }

    }

    handleDvResponseMessage_delayed(message: PayCentral.Internal.InboundPaymentMessage): PayCentral.PaymentResult {

        let succeeded = false;
        if (typeof message.json["TokenizationSuccessful"] === "boolean")
            succeeded = message.json["TokenizationSuccessful"];

        if (!succeeded) {
            logger.warn(
                "Expected the result of submit() for a delayed capture request to yield TokenizationSuccessful===true");
            return {
                succeeded: false,
                capture: "Delayed",
                errors: ["Payment failed for an unknown reason"]
            };
        }

        this.complete = true;

        return {
            succeeded: true,
            capture: "Delayed",
            // TODO JB only return what is required
            data: message.json,
            threeDSCode: message.threeDSCode ?? null
        };

    }

    handleDvResponseMessage_immediate(message: PayCentral.Internal.InboundPaymentMessage): PayCentral.PaymentResult {

        this.complete = true;

        let threeds = message.threeDSCode ?? null;

        return { succeeded: true, capture: "Immediate", data: message.json.transactionIntentId };

    }

    handleDvResponseMessage_externalerror(): PayCentral.PaymentResult {
        // assumption that UI has provided the user with all necessary errors, no SDK errors required.

        return {
            succeeded: false,
            capture: this.paymentMethod.capture,
            errors: null
        };

    }

    handleDvResponseMessage_error(message: PayCentral.Internal.InboundPaymentMessage): PayCentral.PaymentResult {
        // assumption that response.data contains validation errors

        let errors = (message.data ?? "").split("#").map(x => x.trim()).filter(x => x.length > 0) ??
            [];
        if (errors.length === 0) {
            logger.warn(
                "submit() completed but returned no data. Expected either a json object or a validation error string.");
            errors = ["Payment failed for an unknown reason"];
        }

        return {
            succeeded: false,
            capture: this.paymentMethod.capture,
            errors: errors
        };

    }

    async handleDvChallenge(message: PayCentral.Internal.InboundPaymentMessage): Promise<PayCentral.Internal.ThreeDsChallengeResult> {
        return await threedsFactory.getChallengeHandler(message).challenge();
    }

    async load(container: HTMLElement, options?: PayCentral.ShowOptions): Promise<void> {

        logger.verboseinfo("paycentral.sdk.paymentcontrol.load() called.", this.getLogProperties());

        if (this.loadedState !== "new") throw errorHandler.toPayCentralError("The payment control is either already loaded or is in the process of loading.");
        this.throwIfDestroyed();

        try {

            this.loadedState = "loading";

            // Attempt load in an abortable manner.
            // Calling destroy() before the load() has completed will reject the load promise and teardown anything it has done (like adding a frame to the page).
            // This allows for a scenario where a consumer might want to change payment method and load a new paymentcontrol without having to first wait 
            // for the previous paymentcontrol to finish loading.
            this.abortableOperation =
                abortable.AbortableOperation((abortableOperation) => this.loadAbortable(abortableOperation, container, options));

            logger.verboseinfo("paycentral.sdk.paymentcontrol.load() begin await execution of the combined load operation.", this.getLogProperties());
            await this.abortableOperation.execute();
            logger.verboseinfo("paycentral.sdk.paymentcontrol.load() end await execution of combined load operation.", this.getLogProperties());

            this.loadedState = "loaded";

            // now the control has loaded we can load the elements control within it
            // this is just for Stripe but we don't know the gateway at this point
            // so other gateways will ignore this
            let message = {
                action: "CURRENCY-AMOUNT",
                amount: this.paymentRequestOptions.amount.amount,
                currency: this.paymentRequestOptions.amount.currency
            };
            this.postOutboundMessage(message);

            logger.verboseinfo("paycentral.sdk.paymentcontrol.load() completed successfully, the payment control has loaded.", this.getLogProperties());

        } catch (e) {
            logger.verboseinfo("paycentral.sdk.paymentcontrol.load() failed with error.", { ...this.getLogProperties(), error: e });
            this.loadedState = "failed";
            throw e;
        } finally {
            this.abortableOperation = undefined;
        }

    }

    async loadAbortable(abortableOperation: PayCentral.Internal.AbortableOperation<PayCentral.Internal.PaymentControl>, container: HTMLElement, options?: PayCentral.ShowOptions): Promise<PayCentral.Internal.PaymentControl> {

        const src = this.buildFrameSrc(options?.customCss ?? false);

        let timeout = this.getTimeout(flags.loadTimeout, options?.timeout, frameLoadTimeout);

        const showOnError = options?.showOnError ?? flags.showOnError;

        const showOnLoad = this.shouldDisplayControlOnLoad(this.paymentMethod, this.paymentRequestOptions);

        let stage = 0;

        try {

            logger.verboseinfo("paycentral.sdk.paymentcontrol.loadAbortable() begin await attach external scripts.", this.getLogProperties());
            await this.loadExternalScriptsAbortable(abortableOperation);
            stage = 1;
            logger.verboseinfo("paycentral.sdk.paymentcontrol.loadAbortable() end await attach external scripts.", this.getLogProperties());

            logger.verboseinfo("paycentral.sdk.paymentcontrol.loadAbortable() begin await create and load frame.", this.getLogProperties());
            const f = await abortableOperation.awaitAbortablePromise(this.createHiddenFrameAbortablePromise(container, src, timeout));
            stage = 2;
            logger.verboseinfo("paycentral.sdk.paymentcontrol.loadAbortable() end await create and load frame.", this.getLogProperties());

            // Something loaded in time, so show it (if applicable).
            f.style.display = showOnLoad ? "inline" : "none";

            this.iframe = f;
            this.iframeSrc = src;

            if (flags.logFrameSrc) console.debug(`FrameSrc = ${src}`);

            // subscribe to messages
            messaging.subscribe(this);

            // If the frame cannot load from DV e.g. DV offline, the browser will often populate the frame with its default "missing content" page.
            // It will then fire the load event to indicate that something is loaded.
            // To test whether its actually a payment control we can ping it.
            logger.verboseinfo("paycentral.sdk.paymentcontrol.loadAbortable() begin await ping the frame.", this.getLogProperties());
            if (!(await this.ping(abortableOperation))) throw errorHandler.toPayCentralError("The payment control is unresponsive.");
            stage = 3;
            logger.verboseinfo("paycentral.sdk.paymentcontrol.loadAbortable() end await ping the frame.", this.getLogProperties());

            // load webui
            logger.verboseinfo("paycentral.sdk.paymentcontrol.loadAbortable() begin await send ui.load message.", this.getLogProperties());
            const result = await this.sendMessageRequest(
                helper.composeActionMessageData("ui.load", this.paymentMethod, this.paymentRequestOptions, { sdkContainerId: container.id }),
                abortableOperation);
            stage = 4;
            logger.verboseinfo("paycentral.sdk.paymentcontrol.loadAbortable() end await send ui.load message.", this.getLogProperties());
            if (!result) {
                throw errorHandler.toPayCentralError("The payment control user interface could not be loaded.");
            }

            // monitor it in case something unexpected gets loaded into it
            this.startFrameMonitor();

            return this.api();

        } catch (e) {
            if (abortableOperation.aborted) {
                logger.verboseinfo("paycentral.sdk.paymentcontrol.loadAbortable() failed by abort.", this.getLogProperties());
                // must rethrow errors caused by an abort
                throw e;
            }
            else {
                logger.verboseinfo("paycentral.sdk.paymentcontrol.loadAbortable() failed with error.", { ...this.getLogProperties(), error: e });
                this.destroy(showOnError && stage >= 2, true);
                throw errorHandler.toPayCentralError(e);
            }

        }

    }

    getTimeout(...values): number | null {
        for (const value of values) {
            if (!utilities.isOneOf(typeof (value), "string", "number")) continue;
            const timeout = parseInt(value);
            if (isNaN(timeout)) continue;
            if (timeout <= 0) continue;
            return timeout;
        }
        return null;
    }

    async loadExternalScriptsAbortable(abortableOperation: PayCentral.Internal.AbortableOperation<any>): Promise<void> {
        const scripts = actions.getProvider(this.paymentMethod.gateway)?.requiredScripts ?? threedsFactory.getExternalScripts(this.paymentMethod);
        const results = await abortableOperation.awaitAbortablePromise(scriptmanager.loadScriptsAbortablePromise(scripts));
        if (!results.succeeded) {
            const error = `Error loading required scripts\r\n${results.errors.join("\r\n")}`;
            throw errorHandler.toPayCentralError(error);
        }
    }

    // Load the payment frame into a container but keep it hidden
    createHiddenFrameAbortablePromise(container: Element, src: string, timeoutms: number): PayCentral.Internal.AbortablePromise<HTMLIFrameElement> {

        let _resolve, _reject;
        let settled = false;

        const promise: Promise<HTMLIFrameElement> = new Promise((resolve, reject) => {
            _resolve = resolve;
            _reject = reject;
        });

        const f = document.createElement("iframe");
        f.id = f.name = `paycentralframe${frameId++}`;
        f.style.width = "100%";
        f.style.height = "100px";
        f.classList.add("paycentralFrame");
        f.style.marginLeft = "-9px";
        f.style.border = "0";
        f.style.display = "inline";
        f.referrerPolicy = "strict-origin-when-cross-origin";
        f.scrolling = "no";

        const load = () => {
            if (settled) return;
            settled = true;
            f.removeEventListener("load", load);
            clearTimeout(timer);
            _resolve(f);
        }

        f.addEventListener("load", load);

        f.src = src;
        if (container.prepend)
            container.prepend(f);
        else {          // IE11
            if (container.childNodes && container.childNodes.length > 0)
                container.insertBefore(f, container.childNodes[0]);
            else
                container.appendChild(f);
        }

        const timeout = () => {
            if (settled) return;
            settled = true;
            f.removeEventListener("load", load);
            // Always remove the frame if no page loaded in time.
            // We don't want to leave the frame and give the page a chance to complete loading
            // AFTER we have rejected and returned an error.
            // It would be extremely confusing to the user to a see a message saying the payment control
            // did not load, but then to see it appear and be useable.
            remove(f);
            _reject("The payment control did not load within the expected time frame.");
        }

        const timer = setTimeout(timeout, timeoutms);
        this.timers.push(timer);

        // action to perform if the promise is aborted before it resolves or rejects
        const aborter = (reason: string) => {
            if (settled) return;
            settled = true;
            logger.info("Destroying the hidden frame");
            f.removeEventListener("load", load);
            clearTimeout(timer);
            remove(f);
            _reject(reason);
        }

        const remove = (f: HTMLIFrameElement) => {
            if (typeof f.remove === "function")
                f.remove();
            else
                f.parentNode.removeChild(f);  // IE11
        };

        return abortable.AbortablePromise(promise, aborter);

    }

    async ping(abortableOperation?: PayCentral.Internal.AbortableOperation<unknown>): Promise<boolean> {

        const id = ++this.pingCounter;

        try {

            let aborter;

            const pingPromise: Promise<boolean> = new Promise((resolve, reject) => {
                let settled = false;
                const timeout = setTimeout(() => {
                    if (settled) return;
                    settled = true;
                    logger.info(`Ping timed out`);
                    resolve(false);
                }, 5000);
                this.timers.push(timeout);
                this.registerPingHandler(id, () => {
                    if (settled) return;
                    settled = true;
                    resolve(true);
                }, timeout);
                aborter = (reason?: string) => {
                    if (settled) return;
                    settled = true;
                    clearTimeout(timeout);
                    reject(reason);
                }
            });

            this.postOutboundMessage(`ping-${this.pingGroup}-${id}`);

            if (abortableOperation) {
                // if we are inside an abortable operation, then await ping in an abortable manner
                return await abortableOperation.awaitAbortablePromise(abortable.AbortablePromise(pingPromise, aborter));
            }

            return await pingPromise;


        } finally {
            this.destroyPingHandler(id);
        }

    }

    registerPingHandler(id, callback, timeout): void {
        const handler = {
            id: id,
            callback: callback,
            active: true,
            destroy: () => {
                clearTimeout(timeout);
                handler.active = false;
            }
        };
        this.pingHandlers.push(handler);
    }

    destroyPingHandler(id): void {
        const i = this.pingHandlers.findIndex((h) => h.id === id);
        if (i !== -1) {
            this.pingHandlers[i].destroy();
            this.pingHandlers.splice(i, 1);
        }
    }

    handlePingResponse(response): boolean {
        if (!this.pingHandlers?.length) return false;
        const prefix = `pong-${this.pingGroup}-`;
        if (!response.startsWith(prefix)) return false;
        const id = Number(response.substring(prefix.length));
        if (isNaN(id)) return false;
        const handler = this.pingHandlers.find((h) => h.id === id && h.active);
        if (!handler) return false;
        handler.callback();
        this.destroyPingHandler(handler.id);
        return true;
    }

    postOutboundMessage(data: any, targetOrigin?: string): void {

        logger.verboseinfo("paycentral.sdk.paymentcontrol.postOutboundMessage() called.", { ...this.getLogProperties(), message: data });

        this.iframe.contentWindow.postMessage(data, targetOrigin ?? this.getFrameOrigin());

    }

    // waits for a message
    // if one is received it will be returned else null
    async waitForInboundMessage(timeout: number): Promise<PayCentral.Internal.InboundMessage> {

        const existingListener = this.messageListener;

        let listenerResolve,
            listenerReject,
            timer;

        const listener: Promise<PayCentral.Internal.InboundMessage> = new Promise((resolve, reject) => {
            listenerResolve = resolve;
            listenerReject = reject;
            timer = setTimeout(() => resolve({ timeout: true }), timeout);
            this.timers.push(timer);
        });

        this.messageListener = {
            terminate: (error?: string) => listenerReject(error),
            receive: (data: any) => listenerResolve({ data: data })
        }

        try {
            return await listener;
        } catch (e) {
            throw e;
        } finally {
            clearTimeout(timer);
            this.messageListener = existingListener;
        }

    }

    getFrameOrigin() {

        // try URL object
        try {
            const url = typeof URL === "function"
                ? new URL(this.iframe.src)
                : new window.URL(this.iframe.src);
            return url.origin;
        } catch (_) { };

        // simple regex match
        if (this.iframe.src) {
            const matches = this.iframe.src.match(/^https:\/\/[^/]+/);
            if (matches && matches.length > 1) return matches[0];
        }

        throw errorHandler.toPayCentralError("Could not establish the payment frame origin.");

    }

    destroyUi(doNotRemoveFrame?: boolean) {
        this.stopFrameMonitor();
        if (!doNotRemoveFrame) this.removeFrame();
    }

    removeFrame() {
        try {
            this.stopFrameMonitor();
            if (this.iframe) {
                if (typeof this.iframe.remove === "function")
                    this.iframe.remove();
                else
                    this.iframe.parentNode.removeChild(this.iframe); // IE11
            }
        } catch (e) { }
        this.iframe = null;
        this.iframeSrc = null;
    }

    buildFrameSrc(customCss: boolean): string {

        let url = this.paycentralInstance.paycentralUiUrl;

        const paymentMethodType = this.paymentMethod.paymentType;

        if (!url.endsWith("/")) url += "/";

        if (helper.useWebUiVersion2(this.paymentMethod, this.paymentRequestOptions)) {
            url += "payments";
            url += `?token=${this.paycentralInstance.token}`;
            return url;
        }

        url += `widget/${paymentMethodType}`;
        if (paymentMethodType === "DirectDebit")
            url += `/${this.paymentMethod.region}`;

        if (paymentMethodType === "CreditCard" && this.paymentMethod.capture === "Immediate") {
            url += "/capture";
            // For now we will restrict the addition of the gateway-id to credit card only.
            // When we implement external payment-method UIs fr direct debit we can move this
            // out of the card logic and address the related concerns/changes.
            if (this.paymentMethod.gateway.id !== null) {
                url += `/${this.paymentMethod.gateway.id}`;
            }
        }

        // used in test to force invalid frame src
        if (flags.test_forceInvalidFrameSrc) {
            // use the sdk itself so we see something in the iframe
            url = environment.sdkUrl ?? (url + "/invalid");
        }

        url += `?token=${this.paycentralInstance.token}&flow=${helper.captureModeToWidgetFlow(this.paymentMethod.capture)}`;

        var traceId = window?.appInsights?.context?.telemetryTrace?.traceID
        if (typeof traceId !== "undefined") {
            url += "&requestId=" + traceId;
        }

        if (customCss) url += "&css=true";
        if (this.paymentMethod.gateway.options?.use3ds === true) url += "&3ds=true";

        return url;

    }

    // Need to use an anonymous function to access frameMonitor() from an event listener
    // so that "this" has the correct context (i.e. so "this" refers to "this" instance of the class) when frameMonitor() is called.
    // Create and reference the function so that it can be used later to remove the event listener.
    frameMonitorCaller = () => this.frameMonitor();

    startFrameMonitor() {
        this.iframe.addEventListener("load", this.frameMonitorCaller);
    }

    stopFrameMonitor() {
        if (this.iframe) {
            try {
                this.iframe.removeEventListener("load", this.frameMonitorCaller);
            } catch (e) { }
        }
    }

    // called every time new content gets loaded into the frame
    async frameMonitor() {
        logger.verboseinfo("paycentral.sdk.paymentcontrol.frameMonitor() called. The iframe reported a new page was loaded.", this.getLogProperties());

        const count = ++this.frameMonitorCount;

        logger.verboseinfo("paycentral.sdk.paymentcontrol.frameMonitor() begin await ping the frame", { ...this.getLogProperties(), frameMonitorCount: this.frameMonitorCount });
        var active = await this.ping();
        logger.verboseinfo("paycentral.sdk.paymentcontrol.frameMonitor() end await ping the frame", { ...this.getLogProperties(), frameMonitorCount: this.frameMonitorCount, active: active });

        // if the frame content has moved on since we pinged, then bail out
        if (count !== this.frameMonitorCount) return;

        this.frameUnresponsive = !active;
        if (this.frameUnresponsive) {

            logger.verboseinfo("paycentral.sdk.paymentcontrol.frameMonitor() frame appears unresponsive.", this.getLogProperties());

            // call all subscribers that are interested if the frame is unresponsive
            for (let sub of this.frameUnresponsiveSubscriptions) {
                try {
                    sub.callback();
                } catch (e) { }
            }
        }
    }

    subscribeFrameUnresponsive(callback): number {
        const id = ++this.frameUnresponsiveSubscriptionCount;
        this.frameUnresponsiveSubscriptions.push({
            id: id,
            callback: callback
        });
        return id;
    }

    unsubscribeFrameUnresponsive(id) {
        const i = this.frameUnresponsiveSubscriptions.findIndex((e) => e.id === id);
        if (i !== -1) {
            this.frameUnresponsiveSubscriptions.splice(i, 1);
        }
    }

    // contains a promise that will reject with the PayCentral.ComponentUnresponsive error when an unresponsive frame is detected
    createFrameUnresponsivePromise(): FrameUnresponsivePromise {

        let subId;
        const promise: Promise<void> = new Promise((resolve, reject) => {
            // we never resolve, we only reject when we detect an unresponsive frame
            subId = this.subscribeFrameUnresponsive(() => reject(errorHandler.error_ComponentUnresponsive()));
        });

        const destroy = () => {
            this.unsubscribeFrameUnresponsive(subId);
        }

        return {
            promise: promise,
            destroy: destroy
        };

    }

    async sendMessageRequest(message, abortableOperation?: PayCentral.Internal.AbortableOperation<unknown>): Promise<boolean> {

        const id = Math.floor(Math.random() * 10000000);
        message.id = id;

        try {

            let aborter;

            const messagePromise: Promise<boolean> = new Promise((resolve, reject) => {
                let settled = false;
                const timeout = setTimeout(() => {
                    if (settled) return;
                    settled = true;
                    logger.info(`Load payment control user interface message timed out`);
                    resolve(false);
                }, 5000);
                this.timers.push(timeout);
                const handler = {
                    id: id,
                    active: true,
                    callback: (data) => {
                        if (settled) return;
                        settled = true;
                        resolve(true);
                    },
                    destroy: () => {
                        clearTimeout(timeout);
                        handler.active = false;
                    },
                    type: "ack"
                };
                this.messageHandlers.push(handler);
                aborter = (reason?: string) => {
                    if (settled) return;
                    settled = true;
                    clearTimeout(timeout);
                    reject(reason);
                }
            });

            this.postOutboundMessage(message);

            if (abortableOperation) {
                // if we are inside an abortable operation, then await response in an abortable manner
                return await abortableOperation.awaitAbortablePromise(abortable.AbortablePromise(messagePromise, aborter));
            }

            return await messagePromise;

        } finally {
            this.destroyMessageHandler(id);
        }

    }

    handleMessageResponse(response): boolean {
        if (!this.messageHandlers?.length) return false;
        const id = Number(response.id);
        if (isNaN(id)) return false;
        const handler = this.messageHandlers.find((h) => h.id === id && h.type === response.type && h.active);
        if (!handler) return false;
        this.destroyMessageHandler(handler.id);
        handler.callback(response);
        return true;
    }

    destroyMessageHandler(id): void {
        const i = this.messageHandlers.findIndex((h) => h.id === id);
        if (i !== -1) {
            this.messageHandlers[i].destroy();
            this.messageHandlers.splice(i, 1);
        }
    }

    getLogProperties() {
        return { token: this.paycentralInstance?.token };
    }

    /**
     * Determines whether a control should be displayed on load based on the payment method and request options.
     * By default, the payment control UI is shown immediately to the user upon loading. However, for certain
     * providers and payment methods, the display of the UI may be deferred, allowing the provider to decide the
     * appropriate time to show the UI.
     *
     * @param method - The payment method to be checked.
     * @param options - The payment request options containing currency and other details.
     * @returns A boolean indicating whether the control should be displayed on load.
     */
    shouldDisplayControlOnLoad(method: PayCentral.PaymentMethod, options: PayCentral.PaymentRequestOptions): boolean {
        // Default behavior is to show the control on load
        let showOnLoad = true;

        // Check specific conditions for deferring the UI display for Stripe and DirectDebit in GBP
        if (/stripe/i.test(method.gateway.provider)) {
            const isStripeBacsDirectDebit = method.paymentType === "DirectDebit" && options.amount.currency.toUpperCase() === "GBP";
            showOnLoad = !isStripeBacsDirectDebit;
        }

        return showOnLoad;
    }
}