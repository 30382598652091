"use strict";

import * as utilities from "paycentral.sdk.utilities";

enum FrameType {
    CreditCard = 1,
    DirectDebit = 2
}

function composeMessageData(paymentMethod: PayCentral.PaymentMethod,
    paymentRequestOptions: PayCentral.PaymentRequestOptions,
    frameSrc: string,
    origin: string) {

    if (useWebUiVersion2(paymentMethod, paymentRequestOptions)) {
        return composeActionMessageData("ui.submit", paymentMethod, paymentRequestOptions);
    }

    const data: PayCentral.Internal.PayCentralMessageData = {
        DvGatewayID: paymentMethod.gateway.id,
        GatewayProvider: paymentMethod.gateway.provider,
        FrameType: getFrameType(paymentMethod),
        IatsAgentCode: paymentMethod.gateway.options?.iatsAgentCode ?? null,
        Name: {
            FirstName: paymentRequestOptions.billingDetails?.name?.firstName ?? null,
            LastName: paymentRequestOptions.billingDetails?.name?.lastName ?? null,
            FullName: composeFullName(paymentMethod, paymentRequestOptions)
        },
        AccountType:
            paymentMethod.paymentType === "DirectDebit"
                ? paymentRequestOptions.account?.accountType ?? null
                : null, // checking etc for direct debit
        Address: composeBillingAddress(paymentRequestOptions),
        Amount: paymentRequestOptions.amount.amount,
        Currency: paymentRequestOptions.amount.currency,
        IsRecurring: paymentRequestOptions.recurring ?? false,
        supports3DS: composeSupports3ds(paymentMethod, paymentRequestOptions), // TODO JB comment in paycentral.js in iMIS says this can be removed
        paymentRequestData: composePaymentRequestData(paymentMethod, paymentRequestOptions, origin),
        paymentRequestOptions: {
            // ModuleTarget: null,
            ProcessingFlow: captureModeToWidgetFlow(paymentMethod.capture),
            Request3Ds: true, // always true
            Src: frameSrc,
            MerchantOrigin: origin
        }
    };

    return data;

}

function composeActionMessageData(type: string,
    paymentMethod: PayCentral.PaymentMethod,
    paymentRequestOptions: PayCentral.PaymentRequestOptions) {

    const action: PaymentAction = {
        type: type,
        subtype: "PaymentRequest",
        paymentMethod,
        paymentRequestOptions
    };

    const data: any = {
        type: type,
        action
    };

    return data;
}

function composeFullName(paymentMethod: PayCentral.PaymentMethod, paymentRequestOptions: PayCentral.PaymentRequestOptions): string {

    let name: string;

    if (paymentMethod.paymentType === "CreditCard") {
        // For credit cards the widget expects FullName to be the card holder name.
        name = paymentRequestOptions.card?.cardholderName ?? "";
    } else {
        name = paymentRequestOptions.billingDetails?.name?.fullName;
        if (!name) {
            name = (paymentRequestOptions.billingDetails?.name?.firstName ?? "") +
                " " +
                (paymentRequestOptions.billingDetails?.name?.lastName ?? "");
        }
    }

    name = name.trim();
    return name ? name : null;

}

function getFrameType(paymentMethod: PayCentral.PaymentMethod): FrameType {
    switch (paymentMethod.paymentType) {
        case "CreditCard":
            return FrameType.CreditCard;
        case "DirectDebit":
            return FrameType.DirectDebit;
        default:
            return null;
    }
}

function composeSupports3ds(paymentMethod: PayCentral.PaymentMethod,
    paymentRequestOptions: PayCentral.PaymentRequestOptions): { supports3Ds: boolean, utilize3Ds: boolean } {

    let supports3Ds = false;
    let utilize3Ds = false;

    if (paymentMethod.gateway.provider.toUpperCase() === "IATS") {
        supports3Ds = true;
        utilize3Ds = utilities.isOneOf(paymentRequestOptions.amount.currency.toUpperCase(), "EUR", "GBP");
    }
    if (paymentMethod.gateway.provider.toUpperCase() === "IMIS PAY" || paymentMethod.gateway.provider.toUpperCase() === "GLOBAL PAYMENTS") {
        supports3Ds = true;
        utilize3Ds = utilities.isOneOf(paymentRequestOptions.amount.currency.toUpperCase(), "EUR", "GBP");
    }

    return { supports3Ds, utilize3Ds };
}

function composePaymentRequestData(paymentMethod: PayCentral.PaymentMethod,
    paymentRequestOptions: PayCentral.PaymentRequestOptions,
    origin: string): PayCentral.Internal.PaymentRequestData {

    const billingAddress = composeNamedBillingAddress(paymentRequestOptions);

    const data: PayCentral.Internal.PaymentRequestData = {
        PaymentInstrument: {
            MethodData: {
                Name: paymentMethod.gateway.provider,
                Type: paymentMethod.paymentType,
                GatewayId: paymentMethod.gateway.id,
                GatewayProvider: paymentMethod.gateway.provider,
                Supports3Ds: composeSupports3ds(paymentMethod, paymentRequestOptions).supports3Ds,
            },
            RetainOnSuccess: true,
        },
        TransactionDetails: {
            Payer: {
                FirstName: paymentRequestOptions.billingDetails?.name?.firstName ?? null,
                LastName: paymentRequestOptions.billingDetails?.name?.lastName ?? null,
                CardholderName: paymentMethod.paymentType === "CreditCard"
                    ? paymentRequestOptions.card?.cardholderName ?? null
                    : null,
                BillingAddress: billingAddress
            },
            PayerEmail: null,
            PayerPhone: null,
            PaymentInstrument: {
                MethodData: {
                    Name: paymentMethod.gateway.provider,
                    Type: paymentMethod.paymentType,
                    GatewayId: paymentMethod.gateway.id,
                    GatewayProvider: paymentMethod.gateway.provider,
                    Supports3Ds: composeSupports3ds(paymentMethod, paymentRequestOptions).supports3Ds,
                },
                RetainOnSuccess: true,
            },
            Total: {
                Amount: paymentRequestOptions.amount.amount,
                Currency: {
                    CurrencyCode: paymentRequestOptions.amount.currency,
                },
            },
            Order: {
                OrderId: null,
                OrderTotal: {
                    Amount: paymentRequestOptions.amount.amount,
                    Currency: {
                        CurrencyCode: paymentRequestOptions.amount.currency,
                    },
                },
                ProcessingModel: null,
                ShippingAddress: {},
            }
        },
        Merchant: {
            TenantId: null,
            MerchantOrigin: origin,
        },
        Options: {
            CaptureMode: null,
            PurchaseFlow: captureModeToWidgetFlow(paymentMethod.capture),
            Recurring: false,
            Request3DS: true
        }

    }

    if (paymentMethod.paymentType === "CreditCard") {
        data.PaymentInstrument.Card = {
            ContactName: paymentRequestOptions.card?.cardholderName ?? null,
            Address: billingAddress
        };
        data.TransactionDetails.PaymentInstrument.Card = {
            ContactName: paymentRequestOptions.card?.cardholderName ?? null,
            Address: billingAddress
        };
    }

    return data;

}

function composeBillingAddress(paymentRequestOptions: PayCentral.PaymentRequestOptions): PayCentral.Internal.Address {

    const data =
    {
        AddressId: paymentRequestOptions.billingDetails?.address?.addressId ?? null,
        AddressLines: paymentRequestOptions.billingDetails?.address?.addressLines ?? [],
        CityName: paymentRequestOptions.billingDetails?.address?.cityName ?? null,
        CountryCode: paymentRequestOptions.billingDetails?.address?.countryCode ?? null,
        CountryName: paymentRequestOptions.billingDetails?.address?.countryName ?? null,
        CountrySubEntityCode: paymentRequestOptions.billingDetails?.address?.countrySubEntityCode ?? null,
        CountrySubEntityName: paymentRequestOptions.billingDetails?.address?.countrySubEntityName ?? null,
        PostalCode: paymentRequestOptions.billingDetails?.address?.postalCode ?? null
    }

    return data;

}

function composeNamedBillingAddress(paymentRequestOptions: PayCentral.PaymentRequestOptions): PayCentral.Internal.NamedAddress {

    const data = composeBillingAddress(paymentRequestOptions);
    data.FirstName = paymentRequestOptions.billingDetails?.name?.firstName ?? null;
    data.LastName = paymentRequestOptions.billingDetails?.name?.lastName ?? null;

    return data as PayCentral.Internal.NamedAddress;

}


function captureModeToWidgetFlow(captureMode: PayCentral.CaptureMode) {

    const unrecognized = "";

    if (!(typeof captureMode === "string" && captureMode)) return unrecognized;

    switch (captureMode?.toLowerCase()) {
        case "immediate":
            return "Capture";
        case "delayed":
            return "Tokenize";
        default:
            return unrecognized;
    }
}

/**
 * Use WebUI Version 2 (simplified, action messages)
 * Note: demoing with Stripe Bacs *only* for the time being.
 * @param paymentMethod
 * @param paymentRequestOptions
 * @returns
 */
function useWebUiVersion2(paymentMethod: PayCentral.PaymentMethod, paymentRequestOptions: PayCentral.PaymentRequestOptions) {
    return paymentMethod.gateway.provider === "STRIPE" && paymentMethod.paymentType === "DirectDebit" && paymentRequestOptions.amount.currency === "GBP";
}

interface PaymentAction {
    /**
     * General type of action that needs to be taken by the client
     */
    type: string; //TODO: create type? PaymentActionsType

    /**
     * Refinement of type of action that needs to be taken by the client (currently only applies to the 'sdk' and 'threeDS2' types)
     */
    subtype?: string;

    /**
     * Specifies the payment service provider (gateway) settings.
     */
    gateway?: string;

    /**
     * Specifies the payment method.
     */
    method?: string;

    /**
     * Specifies the payment request options.
     */
    options?: string;

    /**
     * Specifies the payment method.
     */
    paymentMethod?: PayCentral.PaymentMethod;

    /**
     * Specifies the payment request options.
     */
    paymentRequestOptions?: PayCentral.PaymentRequestOptions;

    // PSP

    /**
     * An object containing data to be used in external payment service provider SDKs like Stripe Elements.
     */
    pspData?: {
        [key: string]: any;
    };
}

export {
    composeMessageData,
    captureModeToWidgetFlow,
    useWebUiVersion2
    }

