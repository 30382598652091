"use strict";

import * as logger from "./paycentral.sdk.logger";

/*
 * Wraps a function whose stack awaits one or more AbortablePromise.
 * An AbortableOperation can be aborted from outside the current call stack.
 * Since js is single threaded, this can only happen whilst we are awaiting an operation, at which point code outside the current stack can be executed.
 * To support aborting, the AbortablePromise must be awaited using AbortableOperation.awaitAbortablePromise().
 * Calling AbortableOperation.abort() will abort and reject the pending AbortablePromise.
 * The resulting error is expected to bubble back up to the AbortableOperation wrapper so that it can reject.
 * This requires that the function wrapped by AbortableOperation is designed correctly. It should not catch any exceptions caused by an abort, or if it does, then it must rethrow them.
 * If abort exceptions do not bubble back up to the wrapper then results are unpredictable.
 * If the function to be wrapped does not call AbortableOperation.awaitAbortablePromise() then there is no point in using the wrapper, you are just adding overhead.
 *
 * Example
 *
 * // create an abortable operation that awaits an abortable promise
 * const ao = AbortableOperation(async (abortableOperation) => {
 * 
 *     // do a bunch of synchronous things
 * 
 *     // define a promise and an aborter function that can abort that promise
 *     let aborter;
 *     const promise = new Promise((resolve, reject) => {
 *         // promise will resolve after 10 seconds with the current time
 *         let timeout = setTimeout(() => resolve(new Date()), 10000);
 *         // define an aborter which cancels the timer and rejects the promise
 *         aborter = (reason) => {
 *             clearTimeout(timeout);
 *             reject(reason);
 *         };
 *     });
 * 
 *     // construct the AbortablePromise
 *     const abortablePromise = AbortablePromise(promise, aborter);
 * 
 *     // await the AbortablePromise from within the AbortableOperation so it can be tracked and aborted
 *     const result = await abortableOperation.awaitAbortablePromise(abortablePromise);
 * 
 *     // do a bunch more synchronous things
 * 
 *     return `Resolved with ${result}`;
 * 
 * });
 * 
 * // set a timer that will abort the AbortableOperation after 5 seconds, whilst it is awaiting its inner AbortablePromise
 * setTimeout(() => ao.abort("I was aborted"), 5000);
 * 
 * try {
 *     await ao.execute();
 *     console.log("Did not expect this !");
 * } catch (e) {
 *     if (e === "I was aborted")
 *         console.log("Expected this. YAY it works !!");
 *     else
 *         console.log("Did not expect this");
 * }

 *
 * @param func - The function to wrap. The function is always passed the AbortableOperation as the 1st param.
 * @param finalizer - An optional finalizer which can perform any tidy up operations when the function is aborted
 *
 * @returns {object} - Manager object for interacting with the AbortableOperation
 *   
*/
export function AbortableOperation<T>(func: (abortableOperation, ...args) => unknown, finalizer?: () => void): PayCentral.Internal.AbortableOperation<T> {

    let _executed = false;
    let _settled = false;
    let _aborted = false;
    let _abortReason: string;
    let _resolve, _reject;
    let _abortablePromise: PayCentral.Internal.AbortablePromise<unknown> = null;

    const promise: Promise<T> = new Promise((resolve, reject) => {
        _resolve = resolve;
        _reject = reject;
    });

    async function execute(...args) {
        logger.verboseinfo("paycentral.sdk.abortable.execute() was called.");
        try {

            const result = await func(me, ...args);
            _settled = true;
            // Detect special corner case where the function returns a pre-settled promise
            // OR where the supplied function is synchronous and does not await anything in which case its result will be returned as a resolved promise.
            // AND we forced an abort before the settled value was returned.
            // In this scenario we want to behave like the promise rejected, even though it didn't.
            // I believe this is only necessary because of the way Babel transpiles await to use yield.
            if (_aborted)
                rejectAndFinalize(_abortReason);
            else
                _resolve(result);
        } catch (e) {
            _settled = true;
            if (_aborted) {
                logger.verboseinfo("paycentral.sdk.abortable.execute() failed by abort.");
                rejectAndFinalize(e);
            }
            else {
                logger.verboseinfo("paycentral.sdk.abortable.execute() failed with error.", { error: e });
                _reject(e);
            }
        }
    }

    function rejectAndFinalize(reason) {
        if (finalizer) {
            try {
                finalizer();
            } catch (e) { }
        }
        _reject(reason);
    }

    function scheduleExecute(...args) {
        if (!_executed) {
            _executed = true;
            execute(...args);
        }
        return promise;
    }

    function abort(reason?: string) {
        logger.verboseinfo("paycentral.sdk.abortable.abort() was called.", { reason: reason });
        if (!_executed) throw "AbortableOperation: Cannot call abort() before execute()";
        if (_settled) return;
        _aborted = true;
        _settled = true;
        _abortReason = reason;
        if (_abortablePromise) {
            // Expectation that calling the aborter will reject the promise, 
            // and the thrown error will bubble up to the outer AbortableOperation promise.
            _abortablePromise.abort(reason);
        } else {
            // Assumption here that we are calling abort() synchronously from inside the wrapped function itself
            // i.e. we aren't awaiting an AbortablePromise
            // Essentially we now want to abort the AbortableOperation itself.
            // Best strategy here is to throw an exception which we would then expect to bubble up to the outer AbortableOperation promise 
            // in the same way that would happen if we aborted an AbortablePromise
            throw reason;
        }
    }

    async function awaitAbortablePromise(abortablePromise: PayCentral.Internal.AbortablePromise<T>): Promise<T> {

        logger.verboseinfo("paycentral.sdk.abortable.awaitAbortablePromise() was called.");

        // Only support awaiting one AbortablePromise at a time
        // Possibility we could implement a stack, but no use case at this time so not worth the effort or risk
        if (_abortablePromise) throw "AbortableOperation: Can only await one AbortablePromise at a time";

        try {
            _abortablePromise = abortablePromise;
            return await abortablePromise.promise;
        } finally {
            _abortablePromise = null;
        }

    }

    const me = {
        execute: (...args) => scheduleExecute(...args),
        abort: (reason?: string) => abort(reason),
        awaitAbortablePromise: (abortablePromise: PayCentral.Internal.AbortablePromise<T>) => awaitAbortablePromise(abortablePromise),
        get settled() { return _settled },
        get aborted() { return _aborted }
    }

    return me;

}

/*
 * Wraps a promise adding the notion of an aborter which can abort the pending promise before it has resolved or rejected.
 *
 * @param promise - The promise to wrap
 * @param aborter - Function that should
 *                            (optional) undo/cancel anything the unresolved promise may have done or be doing
 *                            (required) reject the promise using the supplied reason
 *
 */
export function AbortablePromise<T>(promise: Promise<T>, aborter: (reason?: string) => void): PayCentral.Internal.AbortablePromise<T> {

    let aborted = false;
    let abortReason: string;
    let settled = false;

    const promiseWrapper: Promise<T> = new Promise((resolve, reject) => {

        promise
            .then((result) => {
                settled = true;
                // Detect Special corner case where we were dealing with a pre-settled promise e.g. from Promise.resolve()
                // but we forced an abort before the settled value was returned.
                // In this scenario we want to behave like the promise rejected, even though it didn't.
                // I believe this is only necessary because of the way Babel transpiles await to use yield
                if (aborted)
                    reject(abortReason);
                else
                    resolve(result);
            })
            .catch((e) => {
                settled = true;
                reject(e);
            }
            );

    });

    const aborterWrapper = (reason?: string) => {
        if (settled) return;
        aborted = true;
        abortReason = reason;
        aborter(reason);
    }

    return {
        get promise() { return promiseWrapper },
        get abort() { return aborterWrapper },
        get settled() { return settled },
        get aborted() { return aborted }
    }

}

/*
 * Returns a promise that resolves to a given value, but supports the notion of an aborter.
 * Like Promise.resolve() but with an aborter.
 * This addresses a specific issue with the way a Babel transpiled script handles awaiting
 * the result of an async function which returns a pre-settled promise e.g. by using Promise.resolve().
 * We force the promise to resolve on the next available tick, which gives an abort the chance to get in front of it.
 */
export function AbortableResolvedPromise<T>(value?: T): PayCentral.Internal.AbortablePromise<T> {

    let aborter;
    const promise: Promise<T> = new Promise((resolve, reject) => {
        // schedule the promise to resolve on the next available tick
        setTimeout(() => resolve(value), 0);
        aborter = (reason) => reject(reason);
    });

    return AbortablePromise(promise, aborter);

}