import * as errorHandler from "paycentral.sdk.error";

let pcStripe: any;
let paymentElement;
let elements;

export const externalScripts = [ "https://js.stripe.com/v3/" ];

export default function create(): PayCentral.Internal.ActionHandler {
    return new StripeSdkActionHandler();
}

class StripeSdkActionHandler {

    async handleAction(action): Promise<any> {
        switch (action.subType) {
            case "CreateComponent":
                return await this.createComponent(action.pspData);
            case "CollectPaymentMethodDetails":
                return await this.collectPaymentMethodDetails(action.pspData);
            default:
                throw errorHandler.toPayCentralError("Could not establish an appropriate subType action handler.");
        }      
    }

    async createComponent(props): Promise<any> {
        const defaultAmount = 1.00;

        const elementsOptions = {
            amount: defaultAmount * 100,
            appearance: {
                theme: "stripe",
                labels: "floating",
                rules: {
                    ".Tab": {
                        lineHeight: "100%"
                    },
                    ".Label--floating": {
                        opacity: 0,
                        lineHeight: "0px",
                        textTransform: "none",
                        transition: "none"
                    },
                    ".Input::placeholder": {
                        opacity: 0,
                        textTransform: "none",
                        transition: "none"
                    }
                }
            },
            currency: "gbp",
            mode: "payment",
            paymentMethodCreation: "manual"
        };

        const target = document.createElement("div");
        target.setAttribute("id", "payment-element");
        const container = document.getElementById(props.containerId);
        container.prepend(target);

        const paymentElementOptions = {
            layout: "tabs",
            fields: { billingDetails: { address: { country: "never", postalCode: "never" } } },            
            paymentMethodTypes: ["bacs_debit"],
            paymentMethodOrder: ["bacs_debit"]
        };
   
        pcStripe = (window as any).Stripe(props.apiKey, { stripeAccount: props.accountId });

        const options = { elementsOptions, paymentElementOptions };

        try {
            elements = pcStripe.elements(options.elementsOptions);
            paymentElement = elements.create("payment", options.paymentElementOptions);
            await paymentElement.mount("#payment-element");
        } catch (error) {
            console.warn(error);
            throw error;
        }
    }

    async collectPaymentMethodDetails(pspData) {
        const options = pspData.collectPaymentMethodDetails;

        if (!paymentElement) {
            throw new Error("Payment element is not initialized.");
        }

        try {
            this.updateAmountAndCurrency(options.amount, options.currency);

            // Trigger form validation and wallet collection.
            const validation = await elements.submit();
            if (validation.error) {
                return { error: validation.error };
            }

            // Create the PaymentMethod using the details collected by the Payment Element.
            const { paymentMethod, error } = await pcStripe.createPaymentMethod({
                elements: elements,
                params: options.params
            });

            if (error) {
                console.log("[sdk] Stripe Bacs payment method creation error:", error);
                return { error: error.message };
            }

            console.log("[sdk] Stripe Bacs payment method successfully created", paymentMethod);
            return paymentMethod;

        } catch (err) {
            throw err;
        }
    }

    updateAmountAndCurrency(amount, currency) {
        if (elements) {
            elements.update({
                amount: amount,
                currency: currency.toLowerCase()
            });
        }
    }

    get providerName() {
        return "Stripe";
    }
}

/* 

 I. Example 'CreateComponent' action

    "action": {
        "type": "psp",
        "subType": "CreateComponent",
        "pspData": {
            "accountId": "acct_1Oj3QF4EEV0PLF3c",
            "apiKey": "pk_test_51MEF2QKyjehH0XMYAHRTigpS43x9KdZAYfKep6xBk2dI8OyUtBtBxosFNr0CtS19Y0RC9gdU4mjJ0H2W4yQnMssp00avKe9EAM",
            "amount": 1.12,
            "currency": "GBP",
            "componentType": "paymentMethod",
            "paymentMethodType": "bacs",
            "paymentType": "BankAccount",
            "provider": "stripe",
            "containerId": "paymentControlContainer"
        }
    }


 II. Example 'CollectPaymentMethodDetails ' action

    "action": {
        "type": "psp",
        "subType": "CollectPaymentMethodDetails",
        "pspData": {
            "provider": "stripe",
            "collectPaymentMethodDetails": {
                "amount": 100,
                "currency": "GBP",
                "params": {
                    "billing_details": {
                        "address": {
                            "city": "Hobart",
                            "country": "AU",
                            "line1": "51  Macquarie Street",
                            "postal_code": "4130",
                            "state": "TAS"
                        },
                        "email": null,
                        "name": "Jerry Baugh",
                        "phone": null
                    }
                }
            }
        }
    }

 */